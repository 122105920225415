/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTION_LISTS } from '@constants/contentful';
import {
	DEFAULT_CATEGORIES,
	DEFAULT_DEMOS,
	LOCALE_DICT,
	LENS_UPGRADES,
	NEW_BASE_FRAME_NAMES,
	CUSTOMER_JOURNEYS,
	BASE_FRAME_NAMES,
} from '@constants';
import { getProduct } from '@services/shopify';
import { generateLifestyleImages, getGlobalProps, getLensOptions, normalizeHandle } from '@utils/index';
import { normalizeContentfulEntry, transformCollectionsToProducts } from '@utils/normalizers';
import { fetchContentful } from '@services/contentful/client';
import { CopyGroupFields, DemoListFields } from '@ts/contentful';
import { getStars } from '@services/yotpo';
import { NormalizedCollection, NormalizedProduct } from '@ts/product';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { Image } from '@ts/shopify-storefront-api';
import { getAllBaseFrames } from '@services/contentful';
import { fetchAllBaseFrameVariants } from '@services/shopify/hooks/useBaseFrameVariant';
import { BaseVariantPriceDictionary } from './constants/base-skus';

type GetBaseFrameProps = {
	category: (typeof DEFAULT_CATEGORIES)[number];
	demo: (typeof DEFAULT_DEMOS)[number];
	handle: NEW_BASE_FRAME_NAMES;
	locale: (typeof LOCALE_DICT)[keyof typeof LOCALE_DICT]['label'];
};

type GetBasePLPProps = {
	category: (typeof DEFAULT_CATEGORIES)[number] | 'wider-base-frames';
	demo: (typeof DEFAULT_DEMOS)[number];
	locale: (typeof LOCALE_DICT)[keyof typeof LOCALE_DICT]['label'];
};

export type BaseFrameReturn = {
	bfCollectionListSlug: string;
	product: NormalizedProduct;
	prescriptionOptions: any;
	lensOptions: any;
	lifestyleImages: { alt: Image; carousel: Image };
	yotpo: { bottomline: any };
	variantPrices: BaseVariantPriceDictionary;
};

export async function getBaseFrameProps({ category, demo, handle, locale }: GetBaseFrameProps): Promise<BaseFrameReturn> {
	const country = LOCALE_DICT[locale].countryCode;
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const initialData = await Promise.allSettled([
		getGlobalProps({ locale }),
		getMultipleCollections(handle, { skipImages: false, includeDescription: true, country: country }),
		getMultipleCollections('lens-options', {
			includeDescription: true,
			country: country,
		}),
		fetchContentful({
			'content_type': 'demoList',
			'fields.demo': demo,
			'limit': 1,
		}),
		fetchContentful({
			'content_type': 'copyGroup',
			'fields.slug': 'build-flow-rx-options-copy',
			'limit': 1,
			'locale': locale,
		}),
		fetchContentful({
			'content_type': 'copyGroup',
			'fields.slug': 'build-flow-lens-options-copy',
			'limit': 1,
			'locale': locale,
		}),
	]);
	const [globalProps, bfCollection, lensOptions, demoList, rxCopyData, lensCopyData] = (
		initialData as PromiseFulfilledResult<any>[]
	).map(result => result.value);

	const product = transformCollectionsToProducts([bfCollection])[0];

	// "The Larkin" has handle "large-base", eg
	const normalizedHandle = normalizeHandle(product.handle, true);

	const variantPrices = await fetchAllBaseFrameVariants(normalizedHandle + '-black', country);

	const lifestyleImages = generateLifestyleImages({
		demo,
		category,
		name: product.name as `The ${(typeof BASE_FRAME_NAMES)[number]}`,
	});
	const rxCopyGroup = (await normalizeContentfulEntry<CopyGroupFields>(rxCopyData)) as CopyGroupFields;
	const lensCopyGroup = (await normalizeContentfulEntry<CopyGroupFields>(lensCopyData)) as CopyGroupFields;

	const prescriptionOptions = getLensOptions({
		lensOptions: lensOptions.products,
		journey: category,
		demo,
		rxCopyGroup,
		lensCopyGroup,
		currencyCode: currencyCode,
	});
	const routeSpecificProps = {};
	if (category === 'sunglasses') routeSpecificProps['sunglassProduct'] = await getProduct('sunglass-lenses');
	if (category === 'blue-light')
		routeSpecificProps['blueLens'] =
			lensOptions?.products?.find(lens => lens.handle === LENS_UPGRADES.BLUE_LIGHT_FILTERING)?.variants[0] ?? null;

	const yotpo = await getStars(product.id, normalizedHandle);

	const targetDemo = (await normalizeContentfulEntry(demoList)) as DemoListFields;
	const bfCollectionListSlug = targetDemo?.collectionList?.fields?.slug ?? COLLECTION_LISTS.EVERGREEN;

	return {
		...globalProps,
		...routeSpecificProps,
		bfCollectionListSlug,
		lensOptions: lensOptions ?? {},
		lifestyleImages,
		prescriptionOptions,
		product,
		yotpo,
		page: 'product',
		variantPrices,
	};
}

export async function getBasePLPProps({ category, demo, locale }: GetBasePLPProps) {
	const initialData = await Promise.allSettled([
		getGlobalProps({ locale }),
		fetchContentful({
			'content_type': 'demoList',
			'fields.demo': demo,
			'limit': 1,
			'include': 5,
		}),
	]);
	const [globalProps, demoList] = (initialData as PromiseFulfilledResult<any>[]).map(result => result.value);
	if (!demoList) return false;

	const {
		baseFrameProducts,
		hero,
		blueLightHero = undefined,
		sunglassesHero = undefined,
		interactiveCards = [],
		sunglassesInteractiveCards = [],
		seoMetadata,
		seoSunglassesMetadata,
		seoBlueLightMetadata,
		internalName,
		additionalInformation = null,
		sunglassesAdditionalInformation = null,
	} = (await normalizeContentfulEntry(demoList)) as DemoListFields;

	// NOTE: Sets heroData to null if entry is missing or is in DRAFT mode
	let heroData = null;
	let seo = null;
	let additionalInfo = null;
	if (category === CUSTOMER_JOURNEYS.BLUELIGHT) {
		if (seoBlueLightMetadata) seo = seoBlueLightMetadata;
		if (blueLightHero) heroData = blueLightHero;
	} else if (category === CUSTOMER_JOURNEYS.SUNGLASSES) {
		if (seoSunglassesMetadata) seo = seoSunglassesMetadata;
		if (sunglassesHero) heroData = sunglassesHero;
		if (sunglassesAdditionalInformation) additionalInfo = sunglassesAdditionalInformation;
	} else {
		if (hero) heroData = hero;
		if (seoMetadata) seo = seoMetadata;
		if (additionalInformation) additionalInfo = additionalInformation;
	}

	// Base Frame Collection Retrieval
	let collection = (await getAllBaseFrames(true, undefined, baseFrameProducts)) as NormalizedCollection;

	// Remove Mixed-Material Variants
	// MM Base Frames are displayed in separate product cards on PLPs
	collection = {
		...collection,
		products: collection.products.map(product => {
			if (product.handle.includes('mixed-material')) {
				return {
					...product,
					name: product.name.split(' - ')[0],
				};
			}
			return {
				...product,
				variants: product.variants.filter(v => !v.option.includes('Mixed Material')),
			};
		}),
	};

	let isBFSet = true;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// Really bad solution for mixed PLPs. "wider-base-frames" isn't really a journey, and it will only have a men and women version
	// So if we're dealing with this one-off PLP, we fetch a hard-coded collection.
	// Long-term usage of mixed PLPs is TBD so will clean this up another time. Hopefully we just kill it.
	if (category === 'wider-base-frames') {
		isBFSet = false;
		collection = await getMultipleCollections(`${demo}s-wider-base-frames`);
	}

	const collectionName = isBFSet ? internalName : collection?.name;

	if (!collection) {
		throw new Error(
			`Base Frame Collection not found. Could be issue with Base Frame collection naming convention which should include '-frames' at the end of the handle (ex: mens-frames).`
		);
	}

	return {
		...globalProps,
		seoMetadata: seo,
		collection,
		hero: heroData ? { ...heroData, page: 'plp' } : null,
		background: 'gray1',
		interactiveCards: category === CUSTOMER_JOURNEYS.SUNGLASSES ? sunglassesInteractiveCards : interactiveCards,
		isBFSet,
		collectionName,
		additionalInformation: additionalInfo,
	};
}
